.linkSelectInput {
    width: 35%;
    min-width: 250px;
}

.contentLinksTable {
    width: 75%;
    border-spacing: 0;
    table-layout: fixed;
    margin: 0 0 20px 0;
}

.contentLinksTable {
    width: auto;
    border-spacing: 0;
    table-layout: fixed;
    margin: 0 0 20px 0;
}

.contentLinksTable th {
    padding: 20px;
    font-size: 14px;
    text-align: start;
    border-bottom: 1px solid #0000001F;
}

.contentLinksTable.nonEditable th {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    background: #EEE;
}

.contentLinksTable th.colOne {
    width: 40px;
    text-align: center;
}

.contentLinksTable th.colTwo {
    width: 20%;
}

.contentLinksTable th.colFour {
    width: 40px;
}

.contentLinksTable.nonEditable th.colTwo {
    width: auto;
}

.contentLinksTable td {
    padding: 20px;
    border-top: 1px solid #0000001F;
    font-size: 14px;
} 

.contentLinksTable.nonEditable td {
    padding: 10px 20px;
    border-top: 1px solid #0000001F;
} 

.linkSwitchContainer div {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.linkSwitchContainer label {
    display: block;
    margin: 0;
}

.deleteButton {
    cursor: pointer;
    background: #0000;
    border: none;
    padding: 0px;
}

.deleteButton svg {
    height: 27px;
    width: 27px;
}